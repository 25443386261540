import React, { useState } from "react"
import { useIntl } from "gatsby-plugin-intl"

import Layout from "../../layout"
import { Container, Title } from "../../uikit"
import Content from "../BlogPost/components/Content"
import ContactSalesModal from "src/developers/ContactSales"

const Privacy = ({ location, pageContext }) => {
  const { locale } = useIntl()

  const currentNode = pageContext.nodes.find(
    node => node.node_locale.toLowerCase() === locale.toLowerCase()
  )

  const [isContactSalesModalOpen, setIsContactSalesModalOpen] = useState(false)
  const handleModalClose = () => setIsContactSalesModalOpen(false)
  const handleModalOpen = () => setIsContactSalesModalOpen(true)

  if (!currentNode) {
    return null
  }

  return (
    <Layout location={location} openModal={handleModalOpen}>
      <Container>
        <Title>{currentNode.title}</Title>
        <Content json={currentNode.body.json} />
      </Container>
      <ContactSalesModal
        content={{ title: "Contact Sales" }}
        isOpen={isContactSalesModalOpen}
        onClose={handleModalClose}
      />
    </Layout>
  )
}

export default Privacy
